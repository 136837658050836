import React from 'react';
import SEO from './SEO';
import { Box, Grid, Container } from '@mui/material';
import ListaEventos from './ListaEventos';
import Filtro from './Filtro';
import SearchBar from './SearchBar';
import Footer from './Footer';

export default function EventsPage({ eventType, filtroMeses, setFiltroMeses, searchTerm, setSearchTerm }) {
  const getTitle = () => {
    switch(eventType) {
      case 'congressos':
        return 'Congressos Médicos';
      case 'simposios':
        return 'Simpósios Médicos';
      case 'jornadas':
        return 'Jornadas Médicas';
      default:
        return 'Eventos';
    }
  };

  const getDescription = () => {
    const baseDesc = eventType === 'jornadas' 
      ? 'Encontre as melhores jornadas médicas do Brasil. '
      : `Encontre os melhores ${eventType} médicos do Brasil. `;
    const additionalDesc = {
      congressos: 'Congressos médicos atualizados com certificação e programação completa.',
      simposios: 'Simpósios médicos com especialistas e temas relevantes para sua área.',
      jornadas: 'Jornadas médicas com networking e aprendizado intensivo.',
    };
    return baseDesc + (additionalDesc[eventType] || additionalDesc.todos);
  };

  const getCanonicalUrl = () => {
    const baseUrl = 'https://www.agrupamed.com.br';
    return eventType === 'todos' ? baseUrl : `${baseUrl}/${eventType}`;
  };

  return (
    <>
      <SEO 
        title={getTitle()}
        description={getDescription()}
        canonicalUrl={getCanonicalUrl()}
        type="website"
      />
      <Box sx={{ 
        pt: 8, 
        backgroundColor: '#202124', 
        minHeight: '100vh', 
        color: '#e8eaed',
        display: 'flex', 
        flexDirection: 'column' 
      }}>
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}> {/* Filtro is always visible */}
              <Filtro eventType={eventType} onFiltroMesesChange={setFiltroMeses}  />
            </Grid>
            <Grid item xs={12} md={9}> 
              <Box sx={{ mb: 3 }}>
                <SearchBar onSearch={setSearchTerm} />
              </Box>
              <ListaEventos 
                eventType={eventType} 
                filtroMeses={filtroMeses} 
                searchTerm={searchTerm} 
              /> 
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Box>
    </>
  );
}