import React from 'react';
import SEO from './SEO';
import { Typography, Box, Grid, Card, Container, CardContent, CardActions } from '@mui/material';
import { styled } from '@mui/system';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Footer from './Footer';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import image1 from '../images/landingpage/image1.webp';
import image2 from '../images/landingpage/image2.webp';
import image3 from '../images/landingpage/image3.webp';
import image4 from '../images/landingpage/image4.webp';

const StyledButton = styled('button')(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  padding: '12px 24px',
  borderRadius: '30px',
  color: 'white',
  backgroundColor: '#0F435C',
  border: 'none',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  boxShadow: '0 4px 6px rgba(15, 67, 92, 0.2)',
  '&:hover': {
    backgroundColor: '#0C394E',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 8px rgba(15, 67, 92, 0.3)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 20px',
  },
}));

const EventCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  color: 'white',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(15, 67, 92, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    '&:hover': {
      transform: 'none',
      boxShadow: 'none',
    },
  },
}));

const CardButton = styled('a')(({ theme }) => ({
  fontFamily: "'Inter', sans-serif",
  fontSize: '0.875rem',
  fontWeight: 600,
  backgroundColor: 'transparent',
  border: '2px solid #0F435C',
  borderRadius: '20px',
  color: 'white',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  padding: '8px 16px',
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#0F435C',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 16px',
  },
}));

const GradientText = styled('span')({
  background: 'linear-gradient(45deg, #F22720, #C12B1B)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
});

export default function LandingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <SEO 
        title="Eventos Médicos • Congressos, Simpósios e Jornadas"
        description="AgrupaMED - Encontre os melhores congressos, simpósios e jornadas médicas em um só lugar. Seu portal completo de eventos na área da saúde no Brasil."
        canonicalUrl="https://www.agrupamed.com.br/"
        type="website"
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#202124',
        color: 'white',
      }}>
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          {/* Hero Section */}
          <Grid
            container
            spacing={6}
            alignItems="center"
            sx={{
              mt: isMobile ? 4 : 8,
              mb: isMobile ? 6 : 10,
              flexDirection: isMobile ? 'column-reverse' : 'row',
            }}
          >
            <Grid item xs={12} md={6} sx={{ textAlign: isMobile ? 'center' : 'inherit' }}>
              <Typography
                variant={isMobile ? 'h4' : 'h2'}
                component="h1"
                gutterBottom
                fontWeight={800}
                fontFamily="Inter"
                sx={{ mb: isMobile ? 2 : 4 }}
              >
                Descubra eventos da saúde em um <GradientText>único</GradientText> lugar!
              </Typography>
              <Typography
                variant={isMobile ? 'body1' : 'h6'}
                component="p"
                gutterBottom
                fontSize={isMobile ? 16 : 18}
                fontFamily="Inter"
                color="#b3b3b3"
                sx={{ mb: isMobile ? 1 : 4 }}
              >
                Agrupamos diversos eventos médicos em um só lugar, atualizados mensalmente para que você tenha sempre as melhores oportunidades ao seu alcance.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ borderRadius: '30px', overflow: 'hidden', boxShadow: '0 20px 40px rgba(0,0,0,0.3)'}}>
                <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} showIndicators={false} interval={5000} showArrows={false}>
                  <div>
                    <img src={image1} alt="XXIV Congresso Brasileiro de Geriatria e Gerontologia" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  </div>
                  <div>
                    <img src={image2} alt="78º Congresso da Sociedade Brasileira de Dermatologia" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  </div>
                  <div>
                    <img src={image3} alt="XXI Congresso Brasileiro de Atualização em Neurocirurgia - CBAN 2025" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  </div>
                  <div>
                    <img src={image4} alt="LI Congresso Brasileiro de Alergia e Imunologia" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  </div>
                </Carousel>
              </Box>
            </Grid>
          </Grid>

          {/* Tipos de Eventos Section */}
          <Box sx={{ my: isMobile ? 6 : 10 }}>
            <Typography
              variant={isMobile ? 'h4' : 'h3'}
              component="h2"
              align="center"
              mb={isMobile ? 4 : 6}
              fontWeight={700}
              fontFamily="Inter"
            >
              Confira os eventos disponíveis
            </Typography>
            <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
              {[
                { title: "Congressos", icon: BusinessIcon, color: '#61dafb', description: "Grandes eventos com palestras, workshops e networking.", link: "/congressos" },
                { title: "Simpósios", icon: GroupIcon, color: '#f0ad4e', description: "Reuniões de especialistas para debates aprofundados.", link: "/simposios" },
                { title: "Jornadas", icon: CalendarTodayIcon, color: '#5cb85c', description: "Eventos curtos e focados em temas específicos.", link: "/jornadas" },
              ].map((event, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <EventCard>
                    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: isMobile ? 2 : 4 }}>
                      <event.icon sx={{ fontSize: 64, color: event.color, mb: 3 }} />
                      <Typography variant="h5" component="h3" gutterBottom fontWeight={600} sx={{ mb: 2, textAlign: 'center' }}>
                        {event.title}
                      </Typography>
                      <Typography variant="body1" color="#b3b3b3" align="center">
                        {event.description}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', pb: 3 }}>
                      <CardButton href={event.link}>
                        Ver {event.title} <ArrowForwardIcon sx={{ fontSize: 16, ml: 0.5 }} />
                      </CardButton>
                    </CardActions>
                  </EventCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Contact Section */}
          <Box sx={{
            textAlign: 'center',
            p: isMobile ? 4 : 8,
            my: isMobile ? 6 : 10,
            backgroundColor: 'rgba(15, 67, 92, 0.1)',
            borderRadius: '30px',
            backdropFilter: 'blur(20px)',
            boxShadow: '0 10px 30px rgba(15, 67, 92, 0.1)',
          }}>
            <Typography variant={isMobile ? 'h5' : 'h3'} component="h2" gutterBottom fontWeight={700} fontFamily="Inter" sx={{ mb: isMobile ? 3 : 3 }}>
              Não encontrou o que procura?
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mb: 4 }} color="#b3b3b3">
              Ajude-nos a manter nossa lista atualizada. Entre em contato!
            </Typography>
            <StyledButton as="a" href="mailto:agrupamed.contato@gmail.com" sx={{ textDecoration: 'none', fontSize: isMobile ? '0.875rem' : '0.875rem' }}>
              Fale Conosco
            </StyledButton>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
}
