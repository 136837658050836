import * as React from 'react';
import { Card, CardContent, List, ListItem, ListItemText, Checkbox, Typography, CircularProgress, Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function Filtro({ eventType, onFiltroMesesChange }) {
  const [meses, setMeses] = React.useState([]);
  const [selectedMeses, setSelectedMeses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    const API_KEY = process.env.REACT_APP_API_KEY;
    const apiEndpoint = `https://gloomy4.pythonanywhere.com/api/eventos/${eventType}`;
    fetch(apiEndpoint, {
      headers: {
        'x-api-key': API_KEY,
      }
    })
      .then(res => res.json())
      .then(data => {
        const mesesDisponiveis = Object.keys(data.eventos_por_mes);
        setMeses(mesesDisponiveis);
      })
      .finally(() => setIsLoading(false));
  }, [eventType]);

  const handleCheckboxChange = (mes, event) => {
    if (event.target.checked) {
      setSelectedMeses([...selectedMeses, mes]);
    } else {
      setSelectedMeses(selectedMeses.filter(m => m !== mes));
    }
  };

  React.useEffect(() => {
    onFiltroMesesChange(selectedMeses);
  }, [selectedMeses, onFiltroMesesChange]);

  return (
    <Card sx={{ 
      mb: 2,
      backgroundColor: '#282c34', // Slightly lighter charcoal gray
      borderRadius: '8px' 
    }}>
      <CardContent>
        <Typography variant="h6" sx={{ color: '#e8eaed' }}>Filtrar por mês</Typography>
        {isLoading ? ( 
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
            <CircularProgress /> 
          </Box>
        ) : (
          <List>
            {meses.map(mes => (
              <ListItem key={mes} button>
                <Checkbox
                  value={mes}
                  onChange={(event) => handleCheckboxChange(mes, event)}
                  checked={selectedMeses.includes(mes)}
                  sx={{
                    color: '#e8eaed', 
                    '&.Mui-checked': { 
                      color: '#61dafb', 
                    },
                  }}
                />
                <ListItemText primary={formatMonth(mes)} primaryTypographyProps={{ style: { color: 'white' } }} />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
}

Filtro.propTypes = {
  eventType: PropTypes.string.isRequired,
  onFiltroMesesChange: PropTypes.func.isRequired,
};

const formatMonth = (mesAno) => {
  const [year, month] = mesAno.split('-');
  const date = new Date(year, month - 1);
  return date.toLocaleString('pt-BR', { month: 'long', year: 'numeric' }).replace(/^./, str => str.toUpperCase());
};