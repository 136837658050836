import * as React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

export default function SearchBar({ onSearch }) {
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    // Remove acentos e caracteres especiais e converte para minúsculas
    const normalizedSearchTerm = searchTerm
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    onSearch(normalizedSearchTerm);
  };

  return (
    <TextField
      label="Pesquisar Eventos..."
      variant="outlined"
      fullWidth
      onChange={handleSearchChange}
      sx={{ 
        '& .MuiInputLabel-root': { color: '#e8eaed' }, // Light gray label
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' }, // Light gray border
          '&:hover fieldset': { borderColor: '#e8eaed' },
          '&.Mui-focused fieldset': { borderColor: '#61dafb' }, // Blue border when focused
          '& input': { color: '#e8eaed' }, // Light gray input text
        },
      }}
    />
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};