import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import EventsPage from './components/EventsPage';

function App() {
  const [filtroMeses, setFiltroMeses] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(''); // Adicione este estado

  const handleFiltroMesesChange = (novosFiltroMeses) => {
    setFiltroMeses(novosFiltroMeses);
  };
  
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm); // Atualize o estado searchTerm
  };

  return (
    <BrowserRouter>
      <div className="App"> {/* Add padding to the main div */}
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/eventos" element={
            <EventsPage 
              eventType="todos" 
              filtroMeses={filtroMeses} 
              setFiltroMeses={setFiltroMeses} 
              searchTerm={searchTerm} 
              setSearchTerm={setSearchTerm} 
            />} 
          />
          <Route path="/congressos" element={ 
            <EventsPage 
              eventType="congressos" 
              filtroMeses={filtroMeses} // Pass filtroMeses
              setFiltroMeses={setFiltroMeses} // Pass setFiltroMeses
              searchTerm={searchTerm} 
              setSearchTerm={setSearchTerm} 
            />} 
          />
          <Route path="/simposios" element={
            <EventsPage 
              eventType="simposios" 
              filtroMeses={filtroMeses} // Pass filtroMeses
              setFiltroMeses={setFiltroMeses} // Pass setFiltroMeses
              searchTerm={searchTerm} 
              setSearchTerm={setSearchTerm} 
            />} 
          />
          <Route path="/jornadas" element={
            <EventsPage 
              eventType="jornadas" 
              filtroMeses={filtroMeses} // Pass filtroMeses
              setFiltroMeses={setFiltroMeses} // Pass setFiltroMeses
              searchTerm={searchTerm} 
              setSearchTerm={setSearchTerm} 
            />} 
          />
        </Routes>
        <Analytics />
        <SpeedInsights />
      </div>
    </BrowserRouter>
  );
}

export default App;