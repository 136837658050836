import React from 'react';
import { Box, Container, Typography, Link, IconButton, Divider } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const footerStyles = {
  footer: {
    backgroundColor: '#1a1a1c',
    color: '#ffffff',
    padding: '24px 0', // Reduced padding
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px', // Reduced gap
  },
  logo: {
    fontWeight: 700,
    letterSpacing: 1,
    fontSize: '1.5rem', // Smaller font size for logo
    marginBottom: '8px', // Reduced margin
  },
  socialLinks: {
    display: 'flex',
    gap: '8px', // Reduced gap
    marginBottom: '12px', // Reduced margin
  },
  footerLink: {
    color: '#a0a0a0',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    fontSize: '0.875rem', // Smaller font size for links
    '&:hover': {
      color: '#ffffff',
    },
  },
  iconButton: {
    color: '#a0a0a0',
    padding: '6px', // Smaller padding for icons
    transition: 'color 0.3s ease, transform 0.2s ease',
    '&:hover': {
      color: '#ffffff',
      transform: 'scale(1.1)',
    },
  },
  divider: {
    width: '100%',
    backgroundColor: '#333',
    margin: '8px 0', // Reduced margin
  },
  copyright: {
    color: '#808080',
    fontSize: '0.75rem', // Smaller font size for copyright
  },
};

export default function Footer() {
  return (
    <Box component="footer" sx={footerStyles.footer}>
      <Container maxWidth="lg">
        <Box sx={footerStyles.content}>

          <Box sx={footerStyles.socialLinks}>
            <IconButton href="mailto:agrupamed.contato@gmail.com" aria-label="Email" sx={footerStyles.iconButton}>
              <EmailIcon fontSize="small" />
            </IconButton>
            <IconButton href="https://www.instagram.com/agrupamed/" target="_blank" rel="noopener noreferrer" aria-label="Instagram" sx={footerStyles.iconButton}>
              <InstagramIcon fontSize="small" />
            </IconButton>
          </Box>
          {/* 
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '16px', marginBottom: '8px' }}>
            <Link href="#" sx={footerStyles.footerLink}>Sobre</Link>
            <Link href="#" sx={footerStyles.footerLink}>Termos de uso</Link>
          </Box>
          */}
          <Divider sx={footerStyles.divider} />

          <Typography variant="body2" align="center" sx={footerStyles.copyright}>
          AgrupaMED © {new Date().getFullYear()}. Todos os direitos reservados.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}