// ListaEventos.js
import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#282c34',
  borderRadius: '12px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  height: '100%',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
  },
}));

function ListaEventos({ eventType, filtroMeses, searchTerm }) {
  const [eventos, setEventos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const API_KEY = process.env.REACT_APP_API_KEY;
    setIsLoading(true);
    const apiEndpoint = `https://gloomy4.pythonanywhere.com/api/eventos/${eventType}`;

    fetch(apiEndpoint, {
      headers: {
        'x-api-key': API_KEY,
      }
    })
      .then(res => res.json())
      .then(data => setEventos(data.eventos_por_mes))
      .finally(() => setIsLoading(false));
  }, [eventType]);

  const eventosFiltrados = Object.keys(eventos)
    .filter(mes => filtroMeses.length === 0 || filtroMeses.includes(mes))
    .reduce((obj, key) => {
      obj[key] = {
        ...eventos[key],
        eventos: eventos[key].eventos.filter(evento => {
          const normalizedEventoNome = evento[0]
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
          return normalizedEventoNome.includes(searchTerm);
        }),
      };
      return obj;
    }, {});

  const eventosFiltradosSemMesesVazios = Object.fromEntries(
    Object.entries(eventosFiltrados).filter(([mes, dadosMes]) => dadosMes.eventos.length > 0)
  );

  return (
    <Box>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <CircularProgress />
        </Box>
      ) : (
        Object.entries(eventosFiltradosSemMesesVazios).map(([mes, { display, eventos }]) => (
          <Box key={mes} sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }}>
              {display}
            </Typography>
            <Grid container spacing={3}>
              {eventos.map((evento, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <StyledCard>
                    <CardContent >
                      <Typography variant="h6" gutterBottom sx={{ color: '#fff', fontWeight: 'bold' }}>
                        {evento[0]}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#bbb', display: 'flex', alignItems: 'center', mb: 1 }}>
                        <EventIcon sx={{ fontSize: 16, mr: 1 }} />
                        {evento[1]}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#bbb', display: 'flex', alignItems: 'center', mb: 1 }}>
                        <LocationOnIcon sx={{ fontSize: 16, mr: 1 }} />
                        {evento[2]}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', color: '#61dafb' }}>
                        <LinkIcon sx={{ fontSize: 16, mr: 1 }} />
                        <Typography
                          variant="body2"
                          component="a"
                          href={evento[3]}
                          target="_blank"
                          rel="noopener"
                          sx={{
                            color: 'inherit',
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline' },
                          }}
                        >
                          Mais informações
                        </Typography>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))
      )}
    </Box>
  );
}

export default ListaEventos;