import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../images/navbar.svg';

export default function Navbar() {
  const [scrolled, setScrolled] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    const rootElement = document.getElementById('root');
    
    const handleScroll = () => {
      setScrolled(rootElement.scrollTop > 0);
    };
  
    rootElement.addEventListener('scroll', handleScroll);
    return () => rootElement.removeEventListener('scroll', handleScroll);
  }, []);
  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isActive = (path) => location.pathname === path;

  const navItems = [
    { label: 'Início', path: '/' },
    { label: 'Congressos', path: '/congressos' },
    { label: 'Simpósios', path: '/simposios' },
    { label: 'Jornadas', path: '/jornadas' },
  ];

  return (
    <AppBar position="fixed" sx={{
      height: '60px', 
      backgroundColor: scrolled ? 'black' : 'transparent', 
      boxShadow: scrolled ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none', 
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease', 
    }}>
      <Toolbar>
        <Link to="/" style={{ marginLeft: '30px' }}>
          <img src={Logo} alt="AgrupaMED" style={{ width: '150px', height: 'auto' }} />
        </Link>
        <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: 'auto' }}></Box>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, ml: 'auto', display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          <List>
            {navItems.map((item) => (
              <ListItem 
                button 
                component={Link} 
                to={item.path} 
                key={item.label}
                onClick={handleDrawerToggle}
                sx={{
                  backgroundColor: isActive(item.path) ? 'black' : 'white',
                  color: isActive(item.path) ? 'white' : 'black',
                  '&:hover': {
                    backgroundColor: isActive(item.path) ? 'black' : 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>

        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {navItems.map((item) => (
            <Button 
              key={item.label}
              component={Link} 
              to={item.path}
              sx={{
                color: 'white',
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 4,
                  left: '50%',
                  width: isActive(item.path) ? '80%' : '0%',
                  height: '2px',
                  backgroundColor: 'white',
                  transition: 'all 0.3s ease',
                  transform: 'translateX(-50%)',
                },
                '&:hover::after': {
                  width: '90%',
                },
              }}
            >
              {item.label}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}